import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "components/style";
import CourseArrowDown from "assets/svgs/inter-arrow.svg";
import ImageZoomComponent from "components/common/image-zoom";
import {
  ButtonWrapper,
  Button,
  Section,
  StyledTag,
} from "components/pages/course-page/offer/style";

import COURSE_STATUSES from "constants/course-statuses";
import { getCourseStatusIcon } from "utilities/course-utils";
import { StyledCourseStatusTag } from "components/pages/course-page/offer/course-tags/style";
import * as SC from "./style";

const ByHandOffer = ({ data, courseDescr }) => {
  // offer-illustration
  const image = useStaticQuery(graphql`
    query byHandOfferIllustration {
      byHandMainIllustration: file(
        relativePath: { eq: "graphic-design-by-hand/offer-illustration.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const illustrationSrc = image.byHandMainIllustration.childImageSharp.fluid;

  return (
    <Section>
      <Container>
        <ImageZoomComponent customClass="foundation-illustration">
          <SC.MainIllustrationWrapper>
            <SC.TitleContent>
              <SC.StyledByHandLogo />
              <SC.StyledSVGTitle />
              <SC.StyledMasterClassTitle>
                Графдизайн руками
                <span>Начало</span>
              </SC.StyledMasterClassTitle>
            </SC.TitleContent>
            <SC.StyledMainIllustration fluid={illustrationSrc} />
          </SC.MainIllustrationWrapper>
        </ImageZoomComponent>
        <SC.StyledWrapper type={data.coursetype}>
          <SC.Tags>
            <StyledTag
              courseType="Мастер-класс"
              style={{ marginBottom: "1vw" }}
            >
              Мастер-класс
            </StyledTag>
            <StyledCourseStatusTag courseStatus={COURSE_STATUSES.open}>
              {getCourseStatusIcon(true)}В продаже
            </StyledCourseStatusTag>
          </SC.Tags>

          <SC.Info>
            <SC.StyledDescr
              courseTitle={data.coursetitle?.text}
              type={data.coursetype}
            >
              {courseDescr ?? data.coursedescription?.text}
            </SC.StyledDescr>
            <ButtonWrapper>
              <Button to="#participation-section">
                Купить
                <CourseArrowDown />
              </Button>
            </ButtonWrapper>
          </SC.Info>
        </SC.StyledWrapper>
      </Container>
    </Section>
  );
};

export default ByHandOffer;
